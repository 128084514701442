import React, { useEffect, useState } from "react";
import NodeCache from "./node-cache";

import Day from "./Day";

const weatherApiKey = "4GH5D7SBMCCNQCEELTBM3FNJ7";
const itineraryCache = new NodeCache(3600000);

const Trip = (props) => {
  const [itineraries, setItineraries] = useState([]);

  useEffect(() => {
    const getItineraryData = async () => {
      const itinerary = itineraryCache.get(props.queryName);

      if (itinerary !== undefined) {
        setItineraries(itinerary);
        return;
      }

      const itineraryData = await fetch(
        `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${props.queryName}/2022-07-26/2022-08-13?unitGroup=metric&include=days&key=${weatherApiKey}&contentType=json`
      );

      const response = await itineraryData.json();

      let i = response.days.map((f) => {
        const excursion = props.excursions[f.datetime];

        return {
          city: props.name,
          dateString: f.datetime,
          date: new Date(f.datetime),
          minTemp: f.tempmin,
          maxTemp: f.tempmax,
          icon: f.icon,
          excursion: excursion == null ? { title: "Free Day!" } : excursion,
        };
      });

      itineraryCache.set(props.queryName, i);
      setItineraries(i);
    };

    getItineraryData();
  }, []);

  const itineraryBreakdown =
    itineraries &&
    itineraries.map((f) => (
      <Day
        key={f.date}
        startDate={props.startDate}
        endDate={props.endDate}
        itinerary={f}
      />
    ));

  return (
    <>
      <div
        style={{
          fontFamily: "Josefin Sans",
          fontSize: "xx-large",
          textAlign: "center",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        <strong>{props.name}</strong>
      </div>

      <div
        style={{
          overflow: "scroll",
          backgroundImage: `url(${props.name.toLowerCase()}.jpg)`,
          backgroundPositionY: -400,
          backgroundAttachment: "fixed",
          height: "300px",
          marginBottom: "75px",
        }}
      >
        <div>
          <div style={{ display: "flex", marginTop: "60px" }}>
            {itineraryBreakdown}
          </div>
        </div>
      </div>
    </>
  );
};

export default Trip;
