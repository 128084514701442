export default [
  {
    queryName: "Budapest",
    name: "Budapest",
    startDate: new Date("2022-07-26"),
    endDate: new Date("2022-07-29"),
    excursions: {
      "2022-07-29": { title: "Budapest Thermal Bath", time: "14:15 - 17:45" },
    },
  },
  {
    queryName: "Vienna",
    name: "Vienna",
    startDate: new Date("2022-07-30"),
    endDate: new Date("2022-07-31"),
    excursions: {
      "2022-07-30": {
        title: "Mozart & Strauss Concert",
        time: "19:30 - 22:30",
      },
      "2022-07-31": { title: "Panoramic Vienna", time: "08:45 - 12:15" },
    },
  },
  {
    queryName: "Melk,Austria",
    name: "Melk",
    startDate: new Date("2022-08-01"),
    endDate: new Date("2022-08-01"),
    excursions: {
      "2022-08-01": { title: "Melk Abbey", time: "12:15 - 15:45" },
    },
  },
  {
    queryName: "Passau",
    name: "Passau",
    startDate: new Date("2022-08-02"),
    endDate: new Date("2022-08-02"),
    excursions: {
      "2022-08-02": { title: "Bavaria by E-Bike", time: "14:00 - 17:00" },
    },
  },
  {
    queryName: "Regensberg",
    name: "Regensberg",
    startDate: new Date("2022-08-03"),
    endDate: new Date("2022-08-03"),
    excursions: {
      "2022-08-03": { title: "Regensberg Walking Tour", time: "09:15 - 10:45" },
    },
  },
  {
    queryName: "Nuremberg",
    name: "Nuremberg",
    startDate: new Date("2022-08-04"),
    endDate: new Date("2022-08-04"),
    excursions: {
      "2022-08-04": {
        title: "Nuremberg Through History",
        time: "13:30 - 17:30",
      },
    },
  },
  {
    queryName: "Bamberg",
    name: "Bamberg",
    startDate: new Date("2022-08-05"),
    endDate: new Date("2022-08-05"),
    excursions: {
      "2022-08-05": { title: "Bamberg Walking Tour", time: "09:15 - 13:45" },
    },
  },
  {
    queryName: "Würzburg",
    name: "Würzburg",
    startDate: new Date("2022-08-06"),
    endDate: new Date("2022-08-06"),
    excursions: {
      "2022-08-06": {
        title: "Würzburg Residenz & Walk",
        time: "10:00 - 13:00",
      },
    },
  },
  {
    queryName: "Wertheim",
    name: "Wertheim",
    startDate: new Date("2022-08-07"),
    endDate: new Date("2022-08-07"),
    excursions: {
      "2022-08-07": { title: "Wertheim Walking Tour", time: "09:15 - 10:15" },
    },
  },
  {
    queryName: "Koblenz",
    name: "Koblenz",
    startDate: new Date("2022-08-08"),
    endDate: new Date("2022-08-08"),
    excursions: {
      "2022-08-08": { title: "Marksburg Castle", time: "14:30 - 17:30" },
    },
  },
  {
    queryName: "Cologne",
    name: "Cologne",
    startDate: new Date("2022-08-09"),
    endDate: new Date("2022-08-09"),
    excursions: {
      "2022-08-09": { title: "Top of Cologne", time: "15:00 - 17:00" },
    },
  },
  {
    queryName: "Kinderdijk",
    name: "Kinderdijk",
    startDate: new Date("2022-08-10"),
    endDate: new Date("2022-08-10"),
    excursions: {
      "2022-08-10": { title: "Kinderdijk Windmills", time: "14:30 - 16:30" },
    },
  },
  {
    queryName: "Amsterdam",
    name: "Amsterdam",
    startDate: new Date("2022-08-11"),
    endDate: new Date("2022-08-13"),
    excursions: {
      "2022-08-11": { title: "Amsterdam Walking Tour", time: "09:30 - 11:30" },
    },
  },
];
