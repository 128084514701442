export default class NodeCache {
    constructor(expiration) {
        this.expiration = expiration;
        this.lastAccessed = null;
    }

    get(name) {
        if(new Date() - this.lastAccessed > this.expiration) {
            console.log("Cache has expired!");
            this.set(name, undefined);
        }

        this.lastAccessed = new Date();

        const value = localStorage.getItem(name);
        if(value === 'undefined' || !value) return undefined;

        return JSON.parse(value);
    }

    set(name, value) {
        this.lastAccessed = new Date();
        
        const str = JSON.stringify(value);
        localStorage.setItem(name, str);
    }
}