import React, { useState, useEffect } from "react";
import NodeCache from "./node-cache";

const apiKey = "d31ba929-20d3-45f9-b20f-bcdc45de9d4b";
const flightCache = new NodeCache(600000);

function Flight(props) {
  const [flightStatus, setFlightStatus] = useState();

  useEffect(() => {
    const getFlightData = async () => {
      const flight = flightCache.get(props.flightNumber);
      if (flight !== undefined) {
        setFlightStatus(flight);
        return;
      }

      const data = await fetch(
        `https://airlabs.co/api/v9/flight?flight_iata=${props.flightNumber}&api_key=${apiKey}`
      );

      const json = await data.json();
      const response = json.response || {};

      const status =
        response.dep_time === props.scheduledDepartureTime
          ? response.status
          : "-";

      flightCache.set(props.flightNumber, status);
      setFlightStatus(status);
    };

    getFlightData();
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <h1>{props.flightNumber}</h1>
      <h2 style={{ textTransform: "capitalize", fontStyle: "italic", color: flightStatus === "Delayed" ? "red" : "green" }}>
        {flightStatus}
      </h2>

      <div
        style={{
          display: "flex",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ flex: "0 0 *" }}>
          <h1>{props.departureAirport}</h1>
          <h2>{props.departureLongAirport}</h2>
          <h3>{props.departureTime}</h3>
        </div>

        <div style={{ flex: "0 0 10%", textAlign: "center" }}>
          <h1>&#x27f9;</h1>
        </div>

        <div style={{ flex: "0 0 *", textAlign: "center" }}>
          <h1>{props.arrivalAirport}</h1>
          <h2>{props.arrivalLongAirport}</h2>
          <h3>{props.arrivalTime}</h3>
        </div>
      </div>
    </div>
  );
}

export default Flight;
