import React, { useState } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function getDateString(date) {
  const formattedDate = new Date(date).toDateString();
  return formattedDate.substring(0, formattedDate.length - 5);
}

function Itinerary(props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isHovering, setHovering] = useState(false);
  const tick = <span style={{ color: "green" }}> &#x2714;</span>;
  const date = new Date(props.itinerary.date);
  const visiting = props.startDate <= date && date <= props.endDate;
  
  function closeModal(e) {
    e.stopPropagation();
    setModalIsOpen(false);
  }

  return (
    <div
      style={{
        flex: "0 0 33.3%",
        fontSize: "x-large",
        fontFamily: "Josefin Sans",
        textAlign: "center",
        background: "#FFFFFF9F",
        paddingTop: "25px",
        paddingBottom: "25px",
      }}
    >
      <div
        onClick={() => setModalIsOpen(true)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          cursor: isHovering ? "pointer" : "default",
          width: "200px",
          margin: "auto",
        }}
      >
        <strong>
          {getDateString(props.itinerary.date)}
          {visiting ? tick : <></>}
        </strong>

        <div>
          <img alt={props.itinerary.icon} src={props.itinerary.icon + ".png"} />
        </div>

        <div style={{ fontSize: "large" }}>
          {props.itinerary.minTemp}°C - {props.itinerary.maxTemp}°C
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div
          onClick={closeModal}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          style={{
            fontFamily: "Josefin Sans",
            cursor: isHovering ? "pointer" : "default",
            width: "100%",
            height: "100%",
            margin: "auto",
            textAlign: "center",
          }}
        >
          <h2>{props.itinerary.city}</h2>
          <h3>{props.itinerary.dateString}</h3>
          <div>
            <img
              alt={props.itinerary.icon}
              src={props.itinerary.icon + ".png"}
            />
          </div>

          <div style={{ fontSize: "small" }}>
            {props.itinerary.minTemp}°C - {props.itinerary.maxTemp}°C
          </div>

          {visiting && (
            <div>
              <br />
              <hr />

              <h3>Excursion</h3>
              <p>{props.itinerary.excursion.title}</p>
              <p>{props.itinerary.excursion.time}</p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Itinerary;
