import React, { useState } from "react";

import Flights from "./Flights";
import Header from "./Header";
import Trip from "./Trip";
import holiday from "./holiday";
import "./App.css";

function App() {
  const [showFlights, setShowFlights] = useState(false);
  const [showTrip, setShowTrip] = useState(true);

  const itineraries = holiday.map((c) => (
    <Trip
      key={c.name}
      name={c.name}
      queryName={c.queryName}
      startDate={c.startDate}
      endDate={c.endDate}
      excursions={c.excursions}
    />
  ));

  const flights = <Flights />;

  return (
    <div>
      <div style={{ marginBottom: "100px" }}>
        <Header
          showFlights={() => {
            setShowFlights(true);
            setShowTrip(false);
          }}
          showTrip={() => {
            setShowFlights(false);
            setShowTrip(true);
          }}
        ></Header>
      </div>
      {showTrip && itineraries}
      {showFlights && flights}
    </div>
  );
}

export default App;
