import React from "react";

import Flight from "./Flight";

const flights = [
  {
    flightNumber: "KL1072",
    departureAirport: "MAN",
    departureLongAirport: "Manchester",
    departureTerminal: "T2",
    departureTime: "Jul 26 - 09:00",
    scheduledDepartureTime: "2022-07-26 09:00",
    arrivalAirport: "AMS",
    arrivalLongAirport: "Amsterdam",
    arrivalTerminal: "T1",
    arrivalTime: "Jul 26 - 11:15",
  },
  {
    flightNumber: "KL1977",
    departureAirport: "AMS",
    departureLongAirport: "Amsterdam",
    departureTerminal: "T1",
    departureTime: "Jul 26 - 14:25",
    scheduledDepartureTime: "2022-07-26 14:25",
    arrivalAirport: "BUD",
    arrivalLongAirport: "Budapest",
    arrivalTerminal: "2B",
    arrivalTime: "Jul 26 - 16:20",
  },
  {
    flightNumber: "KL1079",
    departureAirport: "AMS",
    departureLongAirport: "Amsterdam",
    departureTerminal: "T1",
    departureTime: "Aug 13 - 17:20",
    scheduledDepartureTime: "2022-08-13 17:20",
    arrivalAirport: "MAN",
    arrivalLongAirport: "Manchester",
    arrivalTerminal: "T2",
    arrivalTime: "Aug 13 - 17:35",
  },
];

function Flights() {
  const f = flights.map((x) => (
    <>
      <Flight key={x.flightNumber} {...x}></Flight>
      <hr />
    </>
  ));

  return <div>{f}</div>;
}

export default Flights;
