import React, { useState } from "react";

function Header(props) {
  const [isHovering, setHovering] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        top: 0,
        width: "100%",
      }}
    >
      <div
        style={{
          flex: "0 0 50%",
          textAlign: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
          cursor: isHovering ? "pointer" : "default",
          backgroundColor: "#96DAC4",
        }}
        onClick={() => props.showFlights()}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <strong>FLIGHTS</strong>
      </div>
      <div
        style={{
          flex: "0 0 50%",
          textAlign: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
          cursor: isHovering ? "pointer" : "default",
          backgroundColor: "#96C4C4",
        }}
        onClick={() => props.showTrip()}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <strong>ITINERARY</strong>
      </div>
    </div>
  );
}

export default Header;
